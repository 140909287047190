.Settings p,
span,
label {
  font-size: 12px;
  line-height: 20px;
  display: inline-block;
}

.Settings {
  width: 100%;
  height: 100%;
  padding: 11px 20px 0px 20px;
  border-bottom: 1px solid #c8c7cc;
}

.SubTitle {
  width: 100%;
  border-bottom: 1px solid #c8c7cc;
  position: relative;
  display: inline-block;
  padding-bottom: 11px;
  cursor: pointer;
}

.SubTitle p {
  font-size: 16px;
  line-height: 20px;
  float: left;
}

.SubTitle img {
  width: 14px;
  height: 14px;
  position: absolute;
  right: 0;
  top: 3px;
  cursor: pointer;
}

.SettingsEmail {
  padding: 5px 0;
}

.SettingsEmail p {
  color: #797979;
}

.SettingsContents {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  margin-bottom: 11px;
}

.SettingsContainer {
  width: 100%;
  margin-top: 7px;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.SettingsContainer input {
  float: right;
  width: 0;
  height: 0;
}

.Checkmark {
  position: absolute;
  height: 14px;
  width: 14px;
  top: 0;
  right: 0;
  margin-top: 3px;
  background-color: #eee;
  border: 1px solid #797979;
}

.SettingsContainer:hover input ~ .Checkmark {
  background-color: #ccc;
}

.SettingsContainer input:checked ~ .Checkmark {
  background-color: #ea718d;
  border: none;
}

.Checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.SettingsContainer input:checked ~ .Checkmark:after {
  display: block;
}

.SettingsContainer .Checkmark:after {
  left: 5px;
  top: 2px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.Open,
.Close {
  transition: 0.5s ease-in-out;
}

.Open {
  height: 120px;
  transition: height 0.3s ease;
}

.Close {
  height: 0;
  transition: height 0.3s ease;
}

.ClosedSettings {
  height: 50px;
}

.ClosedSubTitle {
  border-bottom: none;
  padding-bottom: 0;
}

.ClosedSubTitle p {
  line-height: 6px;
}

.ClosedSubTitle img {
  top: -3px;
}

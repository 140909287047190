.Container {
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 10px;
}

.HeaderContent {
  padding: 15px;
  position: relative;
  border-bottom: 1px solid #838383;
}

.HeaderTitle {
  color: black !important;
  font-size: 20px !important;
  font-weight: 600;
  width: 100%;
  text-align: center;
}

.Close {
  font-size: 20px;
  font-weight: 600;
  width: 15px;
  height: 15px;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 18px;
}

.MainContent {
  padding: 30px 80px;
}

.InfoTitle {
  color: #838383 !important;
  font-size: 17px !important;
  width: 100%;
  margin-top: 5px;
  text-align: center;
}

.Email {
  font-size: 17px !important;
  text-align: center;
  margin-bottom: 30px;
}

.AccessEmail {
  width: 100%;
  position: relative;
  margin-bottom: 20px;
}

.AccessPassword {
  width: 100%;
  margin-bottom: 20px;
  position: relative;
}

.AccessEmail input,
.AccessPassword input {
  width: 100%;
  height: 54px;
  font-size: 17px;
  padding-left: 16px;
  color: black;
  border-radius: 4px;
  border: 1px solid #838383;
}

.AccessEmail label,
.AccessPassword label {
  position: absolute;
  left: 16px;
  top: 17px;
  font-size: 17px;
  color: #797979;
  pointer-events: none;
  transition: all 0.3s ease-in-out;
}

.AccessEmail input:focus ~ label,
.AccessEmail input:valid ~ label {
  top: 1px;
  font-size: 12px;
}

.AccessPassword input:focus ~ label,
.AccessPassword input:valid ~ label {
  top: 2px;
  font-size: 12px;
}

.SpinnerContent {
  left: 0;
  top: calc(50vh - 50px);
  position: absolute;
  width: 100vw;
  position: fixed;
}

.Button {
  background: transparent linear-gradient(180deg, #404a7e 0%, #404a7e 100%) 0%
    0% no-repeat padding-box;
  color: white;
  width: 100%;
  height: 56px;
  font-size: 17px;
  border: none;
  border-radius: 4px;
  margin-top: 20px;
  margin-bottom: 15px;
  cursor: pointer;
}

.Button:hover {
  background: transparent linear-gradient(180deg, #313f85 0%, #313f85 100%) 0%
    0% no-repeat padding-box;
}

@media (max-width: 500px) {
  .HeaderTitle {
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    text-align: center;
  }

  .InfoTitle {
    font-size: 14px;
    width: 100%;
    margin-top: 0;
    margin-bottom: 15px;
  }
}

@media (max-width: 500px) {
  .HeaderTitle {
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    text-align: center;
  }

  .InfoTitle {
    font-size: 14px;
    width: 100%;
    margin-top: 0;
    margin-bottom: 15px;
  }

  .MainContent {
    padding: 30px 30px;
  }
}

.MainHeader {
  width: 100%;
  height: 65.4px;
}

.Container {
  width: 1088px;
  height: 100%;
  margin: 0 auto;
  padding-top: 24px;
}

.Logo {
  float: left;
  width: 128px;
  height: 32px;
}

.AppStoreButton {
  float: right;
  width: 125px;
  height: 36px;
}

@media (max-width: 1366px) {
  .Container {
    width: 1088px;
  }

  .MainHeader {
    height: 65.4px;
  }
}

@media (max-width: 1100px) {
  .Container {
    width: 800px;
  }

  .MainHeader {
    height: 55.4px;
  }
}

@media (max-width: 800px) {
  .Container {
    width: 500px;
  }
}

@media (max-width: 500px) {
  .Container {
    width: 343px;
    padding-top: 20px;
  }
}

.MainHeader {
  width: 100%;
  height: 68px;
  background-color: #7e91f5;
  position: fixed;
  top: 0;
  z-index: 20;
}

.Container {
  width: 904px;
  height: 100%;
  margin: 0 auto;
  padding-top: 18px;
}

.Logo {
  float: left;
  width: 128px;
  height: 32px;
}

.Hamburger {
  width: 27px;
  height: 27px;
  float: right;
  padding-top: 2px;
  display: none;
}

.AppStoreButton {
  float: right;
  width: 125px;
  height: 36px;
  margin-top: -2px;
}

.PageName {
  float: right;
  margin-right: 19px;
  margin-top: 7px;
}

.PageName p {
  font-size: 16px;
  line-height: 19px;
}

@media (max-width: 1000px) {
  .Container {
    width: 343px;
  }

  .Hamburger {
    display: inline-block;
  }

  .AppStoreButton {
    display: none;
  }

  .PageName {
    display: none;
  }
}

.MenuToggle {
  overflow: hidden;
  position: absolute;
  width: 27px;
  height: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.MenuToggle input {
  display: flex;
  width: 32px;
  height: 32px;
  position: absolute;
  cursor: pointer;
  opacity: 0; /* hide input */
  z-index: 1; /* top of the menu */
}

.MenuToggle span {
  width: 27px;
  height: 3px;
  margin-bottom: 4px;
  background: white;
  border-radius: 4px;
  -webkit-transition: all 0.5s cubic-bezier(0.08, 0.81, 0.87, 0.71);
  -moz-transition: all 0.5s cubic-bezier(0.08, 0.81, 0.87, 0.71);
  -ms-transition: all 0.5s cubic-bezier(0.08, 0.81, 0.87, 0.71);
  -o-transition: all 0.5s cubic-bezier(0.08, 0.81, 0.87, 0.71);
  transition: all 0.5s cubic-bezier(0.08, 0.81, 0.87, 0.71);
}

.Span1 {
  transform-origin: 4px 0px;
}

.Span3 {
  transform-origin: bottom right;
}

.MenuToggle input:checked ~ .Span1 {
  background-color: white;
  transform: rotate(45deg) translate(3px);
}
.MenuToggle input:checked ~ .Span2 {
  background-color: white;
  transform: rotate(495deg) translate(2px);
}
.MenuToggle input:checked ~ .Span3 {
  background-color: white;
  transform: rotate(45deg);
  opacity: 0;
}

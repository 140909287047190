.RightSidebar {
  width: 221px;
  float: right;
  border: 1px solid #c8c8cc;
  display: block;
  border-radius: 3px;
  margin-left: 464px;
  padding-bottom: 20px;
  background-color: white;
  z-index: 100;
}

.RightSidebar p {
  color: black;
  font-size: 12px;
  line-height: 22px;
}

.RightSidebar span {
  color: #797979;
  font-size: 12px;
  line-height: 22px;
}

@media (max-width: 1000px) {
  .RightSidebar {
    width: 343px;
    margin: 0 16px;
    display: none;
  }
}

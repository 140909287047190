/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

html *,
html *::before,
html *::after {
  box-sizing: border-box;
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1,
a,
p,
span,
input {
  font-family: 'Avenir';
}

a {
  text-decoration: underline;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SFProText-Regular.woff2') format('woff2'),
    url('./assets/fonts/SFProText-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SFProText-Bold.woff2') format('woff2'),
    url('./assets/fonts/SFProText-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  src: url('./assets/fonts/AvenirLTStd-Black.woff2') format('woff2'),
    url('./assets/fonts/AvenirLTStd-Black.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  src: url('./assets/fonts/AvenirLTStd-Book.woff2') format('woff2'),
    url('./assets/fonts/AvenirLTStd-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  src: url('./assets/fonts/AvenirLTStd-Roman.woff2') format('woff2'),
    url('./assets/fonts/AvenirLTStd-Roman.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
  display: inline-block;
  height: 100%;
}

textarea {
  resize: none;
}

input:focus,
textarea:focus {
  outline: 0;
}

img {
  max-width: 100%;
}

form {
  margin: 0;
}

textarea {
  resize: none;
}

html {
  background-color: #7e91f5;
  background-image: url(./assets/images/background_top.svg);
  background-position: center top;
  background-repeat: repeat-x;
  background-size: contain;
}

body {
  background-image: url(./assets/images/background_bottom.svg);
  background-position: center bottom;
  background-repeat: repeat-x;
  min-height: 100vh;
  margin: 0 auto;
  position: relative;
  background-size: contain;
}

.Container {
  width: 1364px;
  margin: 0 auto;
}

@media (max-width: 800px) {
  html {
    background-image: none;
  }

  body {
    background-size: initial;
  }
}

.Account {
  width: 100%;
  padding: 13px 20px 11px 20px;
  border-bottom: 1px solid #c8c7cc;
}

.SubTitle {
  width: 100%;
  border-bottom: 1px solid #c8c7cc;
  position: relative;
  display: inline-block;
  padding-bottom: 11px;
  cursor: pointer;
}

.SubTitle p {
  font-size: 16px;
  line-height: 20px;
  float: left;
}

.SubTitle img {
  width: 14px;
  height: 14px;
  position: absolute;
  right: 0;
  top: 3px;
  cursor: pointer;
}

.AccountContents {
  overflow: hidden;
}

.AccountContent p,
span {
  line-height: 20px;
}

.AccountContent {
  width: 100%;
  padding-top: 5px;
  display: inline-block;
  overflow: hidden;
}

.AccountContent:nth-child(2) {
  padding-top: 0;
}

.AccountSubContent {
  width: 50%;
  float: left;
}

.AccountChange {
  text-decoration: underline;
  cursor: pointer;
}

.AccoutEdit {
  padding-top: 14px;
}

.AccoutEdit span {
  color: #ea718d;
  cursor: pointer;
}

.Open,
.Close {
  transition: 0.5s ease-in-out;
}

.Open {
  height: 190px;
  transition: height 0.3s ease;
}

.Close {
  height: 0;
  transition: height 0.3s ease;
}

.SubClosed {
  border-bottom: none;
  padding-bottom: 0;
}

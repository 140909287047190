.LabelImage {
  width: 23px;
  height: 23px;
  float: left;
  margin-right: 15px;
  position: absolute;
  z-index: 1;
  top: 10px;
  cursor: pointer;
}

.MainConent {
  padding-top: 92px;
  width: 100%;
  height: 100vh;
  display: block;
  height: 100%;
  display: inline-block;
}

.Container {
  width: 450px;
  padding: 20px 40px;
  border: 2px solid white;
  border-radius: 3px;
  margin: 0 auto;
}

.TitleContent p {
  padding: 10px;
  font-size: 24px;
  line-height: 26px;
  text-align: center;
}

.PasswordContent {
  height: 80;
  margin: 40px 0;
  position: relative;
}

.PasswordContent input {
  width: 100%;
  height: 54px;
  font-size: 17px;
  padding-left: 16px;
  color: #797979;
  border-radius: 4px;
  border: none;
}

.PasswordContent label {
  position: absolute;
  left: 16px;
  top: 16px;
  font-size: 17px;
  color: #797979;
  pointer-events: none;
  transition: all 0.3s ease-in-out;
}

.PasswordContent input:focus ~ label,
.PasswordContent input:valid ~ label {
  top: 2px;
  font-size: 12px;
}

.ButtonContent {
  width: 100%;
  margin: 10px auto;
  padding: 20px 75px;
}

.Submit {
  background: transparent linear-gradient(180deg, #404a7e 0%, #404a7e 100%) 0%
    0% no-repeat padding-box;
  color: white;
  width: 226px;
  height: 56px;
  font-size: 17px;
  border: none;
  border-radius: 4px;
  margin-bottom: 32px;
  cursor: pointer;
}

.Submit:hover {
  background: transparent linear-gradient(180deg, #313f85 0%, #313f85 100%) 0%
    0% no-repeat padding-box;
}

.WarningContent {
  margin-top: 10px;
  color: red;
  font-size: 18px;
}

@media (max-width: 800px) {
  .MainConent {
    padding-top: 200px;
    margin-bottom: 215px;
  }
}

@media (max-width: 500px) {
  .MainConent {
    padding-top: 100px;
    margin-bottom: 150px;
  }

  .Container {
    width: 80%;
  }

  .TitleContent p {
    padding: 0;
    font-size: 18px;
  }

  .ButtonContent {
    width: 80%;
    padding: 0;
    margin-top: 50px;
  }

  .Submit {
    width: 100%;
  }
}

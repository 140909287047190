.Container {
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 10px;
}

.HeaderContent {
  padding: 15px;
  position: relative;
  border-bottom: 1px solid #838383;
}

.HeaderTitle {
  color: black;
  font-size: 20px;
  font-weight: 600;
  width: 100%;
  text-align: center;
}

.Close {
  font-size: 20px;
  font-weight: 600;
  width: 15px;
  height: 15px;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 18px;
}

.MainContent {
  padding: 30px;
}

.InfoTitle {
  color: black;
  font-size: 17px;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 15px;
  text-align: center;
}

.Button {
  background: transparent linear-gradient(180deg, #404a7e 0%, #404a7e 100%) 0%
    0% no-repeat padding-box;
  color: white;
  width: 100%;
  height: 56px;
  font-size: 17px;
  border: none;
  border-radius: 4px;
  margin-top: 20px;
  margin-bottom: 15px;
  cursor: pointer;
}

.Button:hover {
  background: transparent linear-gradient(180deg, #313f85 0%, #313f85 100%) 0%
    0% no-repeat padding-box;
}

@media (max-width: 500px) {
  .HeaderTitle {
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    text-align: center;
  }

  .InfoTitle {
    font-size: 14px;
    width: 100%;
    margin-top: 0;
    margin-bottom: 15px;
  }
}

footer {
  height: 49px;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
}

footer::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 0.6;
}

.Container {
  width: 1088px;
  margin: 0 auto;
  padding-top: 17px;
  position: relative;
}

.CopyrightContent {
  position: absolute;
  left: 0;
}

.Copyright {
  font-size: 16px;
  color: #404a7e;
}

.Menu {
  position: absolute;
  right: 0;
}

.MenuItems {
  display: flex;
  z-index: 2;
}

.MenuItems li {
  padding: 0 16px;
}

.MenuItems li:last-child {
  padding-right: 0;
}

.MenuItems li a {
  color: #404a7e;
  text-decoration: none !important;
}

.Copyright,
.MenuItems li a {
  font-size: 14px;
  line-height: 16px;
}

@media (max-width: 1366px) {
  .Container {
    width: 1088px;
  }

  .Copyright,
  .MenuItems li a {
    font-size: 14px;
    line-height: 16px;
  }
}

@media (max-width: 1100px) {
  .Container {
    width: 800px;
  }

  .Copyright,
  .MenuItems li a {
    font-size: 12px;
    line-height: 14px;
  }
}

@media (max-width: 800px) {
  .Container {
    width: 500px;
  }

  footer {
    margin-top: 50px;
    position: relative;
  }

  footer::before {
    top: none;
    height: 49px;
  }
}

@media (max-width: 500px) {
  footer {
    margin-top: 34px;
    position: relative;
    height: 80px;
  }

  footer::before {
    height: 80px;
  }

  .Container {
    width: 343px;
    position: relative;
  }

  .CopyrightContent {
    width: 100%;
    top: 50px;
    text-align: center;
  }

  .Menu {
    width: 100%;
    top: 18px;
  }

  .MenuItems {
    justify-content: space-between;
  }

  .MenuItems li:first-child {
    padding-left: 0;
  }

  .MenuItems liL:last-child {
    padding-right: 0;
  }
}

.Modal {
  position: fixed;
  z-index: 200;
  background-color: white;
  width: 80%;
  border: 1px solid #ccc;
  border-radius: 7px;
  left: 10%;
  top: 30%;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
}

.ModalChange {
  position: fixed;
  z-index: 200;
  background-color: white;
  width: 80%;
  border: 1px solid #ccc;
  border-radius: 7px;
  left: 10%;
  top: 30%;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
}

@media (min-width: 600px) {
  .Modal {
    width: 517px;
    left: calc(50% - 250px);
  }

  .ModalChange {
    width: 517px;
    left: calc(50% - 250px);
  }
}

@media (max-width: 599px) {
  .Modal {
    top: 20% !important;
  }

  .ModalChange {
    top: 15% !important;
  }
}

.Terms {
  width: 100%;
  padding: 13px 20px 11px 20px;
  border-bottom: 1px solid #c8c7cc;
}

.SubTitle {
  width: 100%;
  position: relative;
  display: inline-block;
  cursor: pointer;
  /* border-bottom: 1px solid #c8c7cc; */
  /* padding-bottom: 11px; */
}

.SubTitle p {
  font-size: 16px;
  line-height: 20px;
  float: left;
}

.SubTitle img {
  width: 14px;
  height: 14px;
  position: absolute;
  right: 0;
  top: 5px;
  cursor: pointer;
}

.AddComment {
  width: 100%;
  display: inline-block;
  position: relative;
  margin-bottom: 16px;
}

.LabelImage {
  width: 23px;
  height: 23px;
  float: left;
  margin-right: 15px;
  position: absolute;
  z-index: 2;
  top: 10px;
  cursor: pointer;
}

.InputComment {
  width: calc(100% - 33px);
  height: 42px;
  position: relative;
  float: right;
}

.LikeImage {
  width: 23px;
  height: 23px;
  position: absolute;
  z-index: 2;
  top: 10px;
  left: 10px;
}

.SmileImage {
  width: 33px;
  height: 33px;
  position: absolute;
  z-index: 2;
  top: 5px;
  right: 60px;
  padding: 5px;
  cursor: pointer;
  border-radius: 20px;
}

.SmileImage:hover {
  background-color: #7e91f5;
}

.InputComment input {
  width: 100%;
  height: 100%;
  border-radius: 3px;
  border: 0.5px solid #c8c7cc;
  font-size: 14px;
  line-height: 16px;
  padding-left: 36px;
  padding-right: 65px;
  position: absolute;
}

.CommentPost {
  position: absolute;
  right: 5px;
  top: 4px;
}

.CommentPost button {
  font-size: 12px;
  line-height: 14px;
  color: #7e91f5;
  border: none;
  background-color: white;
  padding: 10px;
  cursor: pointer;
}

.CommentPost button:hover {
  background-color: #7e91f5;
  color: white;
  border-radius: 2px;
}

.EmojiKeyboard {
  width: calc(100% - 33px) !important;
  margin-left: 32px !important;
}

.EmojiKeyboard section {
  width: 100% !important;
}

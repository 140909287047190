.LeftSidebar span {
  color: black;
}

.LeftSidebar p {
  color: #797979;
}

.LeftSidebar {
  width: 221px;
  float: left;
  border: 1px solid #c8c8cc;
  display: block;
  border-radius: 3px;
  background-color: white;
}

.UserContent {
  padding: 16px 16px 10px;
  height: 100%;
  display: inline-block;
  width: 100%;
  cursor: pointer;
}

.ParentName {
  background-color: #ebebeb;
  padding: 8px 19px 6px;
  border-top: 1px solid #c8c7cc;
  border-bottom: 1px solid #c8c7cc;
  margin: 10px 0;
}

.ParentName p {
  font-size: 16px;
  color: #797979 !important;
}

.UserImage {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  float: left;
  display: block;
  border: 1px solid white;
  overflow: hidden;
}

.AllChildImage {
  box-shadow: 4px 0px #797979;
}

.CheckImage {
  width: 15px;
  height: 15px;
  margin-top: 10px;
  float: left;
  display: block;
  overflow: hidden;
}

.AllImage {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  float: left;
  display: block;
  overflow: hidden;
  box-shadow: 5px 0px #888888;
}

.UserName {
  float: left;
  width: calc(100% - 70px);
  padding-left: 12px;
  padding-top: 8px;
}

.UserName span {
  color: black;
  font-size: 16px;
  line-height: 22px;
}

.DropIcon {
  width: 14px;
  height: 14px;
  float: right;
  padding-top: 13px;
}

.ChildrenList {
  width: 100%;
  max-height: 500px;
  display: block;
  cursor: pointer;
  overflow: auto;
}

.ChildList {
  display: inline-block;
  width: 100%;
  padding-left: 16px;
  cursor: pointer;
}

.ChildList:hover {
  background-color: rgb(232, 232, 232);
}

.OpenChildList,
.CloseChildList {
  transition: height 0.2s ease-in-out;
}

.CloseChildList {
  display: none;
  transition: display 0.2s ease-in-out;
}

.OpenChildList {
  transition: height 0.2s ease;
}

.TimelineDropIcon {
  width: 14px;
  height: 14px;
  float: right;
  position: absolute;
  right: 16px;
  top: 15px;
}

.DateTime {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-top: 1px solid #c8c7cc;
  display: inline-block;
  background-color: white;
}

.TimeLine {
  width: 100%;
  margin-bottom: 16px;
  padding: 10px 19px 0 19px;
  cursor: pointer;
  position: relative;
}

.TimeLine p {
  font-size: 16px;
  line-height: 22px;
}

.YearLines {
  padding: 0 19px;
  width: 100%;
  display: inline-block;
}

.YearLines ul li {
  margin-bottom: 16px;
  display: inline-block;
  width: 100%;
  cursor: pointer;
}

.YearLines ul li:hover {
  padding-left: 13px;
  transition: 0.1s ease-in-out;
}

.YearLines ul li.SetTimeline {
  padding-left: 13px;
  border-left: 3px solid #ea718d;
}

.YearLines ul li.UnsetTimeline {
  padding-left: 0;
}

span.Year {
  font-size: 18px;
  line-height: 22px;
  float: left;
}

.YearLines ul li p {
  float: right;
  font-size: 16px;
  line-height: 22px;
}

.Open,
.Close {
  transition: 0.5s ease-in-out;
}

.Open {
  transition: height 0.4s ease;
}

.Close {
  height: 80px;
  transition: height 0.4s ease;
}

.ParentName {
  width: 100%;
  height: 100%;
  display: block;
  cursor: text;
}

.ParentName p {
  color: red;
}

@media (max-width: 1000px) {
  .LeftSidebar {
    width: 343px;
    margin: 0 16px;
  }
}

h1,
a,
p,
span,
input {
  font-family: 'Avenir';
}

a {
  text-decoration: underline;
}

a,
span,
p {
  color: white;
}

.MainConent {
  padding-top: 50px;
  width: 100%;
  display: inline-block;
  margin-bottom: 50px;
}

.Container {
  width: 1088px;
  margin: 0 auto;
}

.AccessContent {
  width: 453px;
  margin-top: 26px;
  float: left;
}

.AccessTitle {
  width: 100%;
  display: inline-block;
  margin-bottom: 30px;
}

.AccessTitle h1 {
  font-size: 40px;
  font-weight: bold;
  color: white;
  margin-bottom: 16px;
  line-height: 48px;
}

.AccessTitle p {
  font-size: 16px;
  line-height: 19px;
  font-weight: normal;
}

.AccessTitle sup {
  font-size: 17px;
  padding-left: 5px;
  font-weight: 500;
}

.AccessSubTitle {
  margin-bottom: 24px;
}

.AccessSubTitle span,
a,
b {
  font-size: 20px;
  line-height: 24px;
}

.AccessEmail {
  margin-bottom: 22px;
  position: relative;
}

.AccessPassword {
  margin-bottom: 24px;
  position: relative;
}

.AccessEmail input,
.AccessPassword input {
  width: 462px;
  height: 54px;
  font-size: 17px;
  padding-left: 16px;
  color: #797979;
  border-radius: 4px;
  border: none;
  line-height: 20px;
}

.AccessEmail label,
.AccessPassword label {
  position: absolute;
  left: 16px;
  top: 19px;
  font-size: 17px;
  color: #797979;
  pointer-events: none;
  transition: all 0.3s ease-in-out;
}

.AccessEmail input:focus ~ label,
.AccessEmail input:valid ~ label {
  top: 2px;
  font-size: 12px;
}

.AlertEmail p {
  color: red;
  margin-top: -10px;
  margin-bottom: 10px;
}

.AccessPassword input:focus ~ label,
.AccessPassword input:valid ~ label {
  top: 2px;
  font-size: 12px;
}

.AccessPolicy {
  margin-bottom: 12px;
}

.AccessPolicy span,
.AccessPolicy span a {
  font-size: 12px;
  line-height: 14px;
}

.ForgotPassword {
  padding: 15px 0;
  cursor: pointer;
  font-size: 16px !important;
}

.Submit {
  background: transparent linear-gradient(180deg, #404a7e 0%, #404a7e 100%) 0%
    0% no-repeat padding-box;
  color: white;
  width: 226px;
  height: 56px;
  font-size: 17px;
  border: none;
  border-radius: 4px;
  margin-bottom: 26px;
  cursor: pointer;
}

.Submit:hover {
  background: transparent linear-gradient(180deg, #313f85 0%, #313f85 100%) 0%
    0% no-repeat padding-box;
}

.SignupLab {
  margin-bottom: 14px;
}

.SignupLab span {
  font-size: 16px;
}

.SocialLogin {
  height: 48px;
}

.SocialLogin ul {
  margin-left: -3px;
}

.SocialLogin ul li {
  width: 53px;
  height: 53px;
  float: left;
  margin-right: 10px;
  cursor: pointer;
}

.SocialLogin ul li:last-child {
  margin-right: 0;
}

.GroupContent {
  height: 615px;
  width: 615px;
  float: right;
  margin-right: -50px;
}

.SpinnerContent {
  left: 0;
  top: calc(50vh - 50px);
  position: absolute;
  width: 100vw;
  position: fixed;
}

/* @media (max-width: 1366px) {
  .Container {
    width: 1088px;
  }

  .MainConent {
    padding-top: 30.6px;
    margin-bottom: 50px;
  }

  .GroupContent {
    height: 615px;
    width: 615px;
    margin-right: -40px;
  }

  .AccessContent {
    width: 453px;
    margin-top: 26px;
  }

  .AccessTitle {
    margin-bottom: 30px;
  }

  .AccessTitle h1 {
    font-size: 40px;
    margin-bottom: 16px;
    line-height: 48px;
  }

  .AccessTitle p {
    font-size: 16px;
    line-height: 19px;
  }

  .AccessTitle sup {
    font-size: 17px;
    padding-left: 5px;
    font-weight: 500;
  }

  .AccessSubTitle span,
  a,
  b {
    font-size: 20px;
    line-height: 24px;
  }

  .AccessPassword {
    margin-bottom: 24px;
  }

  .AccessEmail input,
  .AccessPassword input {
    width: 462px;
    line-height: 20px;
  }

  .AccessPolicy span,
  .AccessPolicy span a {
    font-size: 12px;
    line-height: 14px;
  }

  .SignupLab span {
    font-size: 16px;
  }

  .Submit {
    margin-bottom: 26px;
  }

  .SocialLogin ul li {
    width: 53px;
    height: 53px;
    margin-right: 10px;
  }

  .GroupContent {
    margin-right: -50px;
  }
} */

@media (max-width: 1100px) {
  .Container {
    width: 800px;
  }

  .MainConent {
    padding-top: 30.6px;
    margin-bottom: 0px;
  }

  .GroupContent {
    height: 435px;
    width: 435px;
    margin-right: -34px;
    margin-top: 20px;
  }

  .AccessContent {
    width: 380px;
    margin-top: 26px;
  }

  .AccessTitle {
    margin-bottom: 20px;
  }

  .AccessTitle h1 {
    font-size: 30px;
    margin-bottom: 12px;
    line-height: 38px;
  }

  .AccessTitle h1 span {
    font-size: 12px;
  }

  .AccessTitle p {
    font-size: 13px;
    line-height: 16px;
  }

  .AccessSubTitle {
    margin-bottom: 15px;
  }

  .AccessSubTitle span {
    font-size: 18px;
  }

  .AccessPassword {
    margin-bottom: 24px;
  }

  .AccessEmail {
    margin-bottom: 18px;
  }

  .AccessEmail input,
  .AccessPassword input {
    width: 365px;
  }

  .AccessPassword {
    margin-bottom: 18px;
  }

  .SignupLab span {
    font-size: 16px;
  }

  .Submit {
    margin-bottom: 18px;
  }
}

@media (max-width: 800px) {
  .Container {
    width: 500px;
    display: flex;
    flex-direction: column-reverse;
  }

  .MainConent {
    padding-top: 30.6px;
    margin-bottom: 0px;
  }

  .GroupContent {
    float: right;
    width: 541px;
  }

  .AccessContent {
    float: right;
    margin-top: 130px;
    width: 100%;
  }

  .AccessTitle {
    width: 100%;
    margin-bottom: 40px;
  }

  .AccessTitle h1 {
    font-size: 44px;
    line-height: 44px;
    margin-bottom: 20px;
  }

  .AccessTitle p {
    font-size: 22px;
    line-height: 24px;
  }

  .AccessSubTitle {
    margin-bottom: 15px;
  }

  .AccessSubTitle span {
    font-size: 22px;
  }

  .AccessEmail input,
  .AccessPassword input {
    width: 100%;
  }

  .AccessPolicy {
    display: block;
    margin-bottom: 30px;
  }

  .AccessPolicy span,
  .AccessPolicy span a {
    font-size: 12px;
    line-height: 14px;
  }

  .SignupLab {
    text-align: center;
  }

  .Submit {
    width: 100%;
    margin-bottom: 26px;
  }

  .SocialLogin {
    width: 100%;
  }

  .SocialLogin ul {
    width: 180px;
    margin: 0 auto;
  }
}

@media (max-width: 500px) {
  .Container {
    width: 343px;
  }

  .MainConent {
    padding-top: 45px;
    margin-bottom: 0px;
  }

  .GroupContent {
    float: right;
    width: 353px;
    height: 353px;
    margin-left: 5px;
  }

  .AccessContent {
    margin-top: 55px;
  }

  .AccessTitle {
    margin-bottom: 32px;
  }

  .AccessTitle h1 {
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 18px;
  }

  .AccessTitle h1 sup {
    font-size: 12px;
    line-height: 14px;
  }

  .AccessTitle p {
    font-size: 14px;
    line-height: 16px;
  }

  .AccessSubTitle {
    margin-bottom: 12px;
  }

  .AccessSubTitle span {
    font-size: 16px;
    line-height: 19px;
  }

  .AccessSubTitle span a b {
    font-size: 16px;
    line-height: 19px;
  }

  .AccessEmail,
  .AccessPassword {
    margin-bottom: 12px;
  }

  .AccessPolicy {
    margin-bottom: 21px;
  }

  .AccessPolicy span,
  .AccessPolicy span a {
    font-size: 12px;
    line-height: 14px;
  }

  .SignupLab span {
    font-size: 16px;
    line-height: 19px;
  }

  .Submit {
    margin-bottom: 21px;
  }

  .SocialLogin {
    width: 100%;
  }

  .SocialLogin ul {
    width: 180px;
    margin: 0 auto;
  }
}

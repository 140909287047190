.MainContents span {
  color: black;
}

.MainContents p {
  color: #424242;
}

.LifeContainer {
  height: 100%;
  width: 100%;
}

.MainContainer {
  padding-top: 88px;
  width: 100%;
  min-height: 100vh;
  display: inline-block;
  background-color: white;
}

.RightContainer {
  background-color: #7e91f5;
}

.Container {
  width: 904px;
  margin: 0 auto;
}

.BottomInfo {
  width: 100%;
}

.AppStoreButton {
  text-align: center;
}

.AppStoreButton a {
  padding-top: 24px;
}

.PageName {
  text-align: center;
  padding-top: 15px;
  padding-bottom: 50px;
}

.Sidebars {
  position: fixed;
}

.MainContents {
  width: calc(100% - 442px);
  padding: 0 20px;
  display: inline-block;
  margin-left: 221px;
}

.MainContent {
  width: 100%;
  border: 1px solid #c8c8cc;
}

.MainImage {
  width: 100%;
}

.Quote {
  width: 100%;
  padding: 18px 18px 3px;
  display: inline-block;
}

.QuoteTitle {
  width: 100%;
  display: inline-block;
  margin-bottom: 5px;
}

.QuoteTitle span {
  font-size: 16px;
  line-height: 13px;
}

.QuoteContent {
  margin-bottom: 12px;
}

.QuoteContent p {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
}

.QuoteDate {
  width: 100%;
  border-bottom: 1px solid #c8c8cc;
  padding-bottom: 15px;
}

.QuoteDate span {
  font-size: 12px;
  line-height: 11px;
  color: #424242;
}

.Comments {
  padding: 16px 18px 23px 18px;
  display: inline-block;
  width: 100%;
  height: auto;
}

.Comment {
  width: 100%;
  display: inline-block;
  padding: 18px;
  border: 0.5px solid #797979;
  background-color: #f5f5f5;
  border-radius: 6px;
  margin-bottom: 10px;
}

.IconContainer {
  width: 45px;
  display: inline-block;
}

.IconContent {
  height: 32px;
}

.ContentContainer {
  float: right;
  width: calc(100% - 45px);
}

.CommentPoster {
  width: 100%;
  display: inline-block;
  margin-bottom: 8px;
}

.CommentPoster span {
  color: #424242;
  font-size: 14px;
  font-weight: 16px;
}

.CommentPoster span b {
  color: black;
}

.CommentContent {
  margin-bottom: 6px;
}

.CommentContent p {
  color: black;
  font-size: 16px;
  line-height: 19px;
}

.CommentDate span {
  font-size: 12px;
  line-height: 14px;
  color: #797979;
}

.EndMark {
  padding: 0 20px;
  border-top: 4px solid #c8c8cc;
  width: 100%;
  display: inline-block;
}

.EndMarkLine {
  margin: 46px auto;
  background-color: #c8c8cc;
  width: 26px;
  height: 6px;
  border-radius: 23px;
}

.SpinnerContent {
  left: 0;
  top: calc(50vh - 50px);
  position: absolute;
  width: 100vw;
  position: fixed;
}

@media (max-width: 1000px) {
  .MainContents {
    width: 375px;
    padding: 0;
    margin: 0 auto;
    margin-top: 20px;
  }

  .Container {
    width: 375px;
  }

  .Sidebars {
    position: relative;
  }

  .LeftSidebar {
    width: 343px;
    margin: 0 16px;
  }

  .RightSidebar {
    width: 343px;
    margin: 0 16px;
    display: none;
  }

  .EndMarkLine {
    margin: 26px auto;
  }
}
